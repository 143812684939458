if (window.lazyImages === undefined) {
  window.lazyImages = true

  const images = document.querySelectorAll('.lazy')

  function preloadImage(img) {
    const src = img.getAttribute("data-src")
    if (!src) { return }
    img.src = src
  }

  const imgOptions = {
    threshold: 0,
    rootMargin: "0px 0px 300px 0px"
  }

  const imgObserver = new IntersectionObserver((entries, imgObserver) => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        return
      } else {
        preloadImage(entry.target)
        imgObserver.unobserve(entry.target)

        images.forEach(image => {
          image.classList.remove('animate-pulse')
        })
      }
    })
  }, imgOptions)

  images.forEach(image => {
    imgObserver.observe(image)
  })
}
